
$bg_img_alpha_std: 0.7;
$bg_alpha_std: 0.7;
$bg_alpha_hover: 0.25;

$blue_light_alpha: #F4FAFFB3;

$blue_light: rgb(244, 250, 255);
$blue_light_wa_zero: rgba(244, 250, 255, 0);
$blue_light_wa_hover: rgba(244, 250, 255, $bg_alpha_hover);
$blue_light_wa_std: rgba(244, 250, 255, $bg_alpha_std);
$blue_light_wa_navbar: rgba(244, 250, 255, .5);

$blue_medium: rgb(0, 124, 178);
$blue_medium_wa_zero: rgba(0, 124, 178, 0);
$blue_medium_wa_hover: rgba(0, 124, 178, $bg_alpha_hover);
$blue_medium_wa_std: rgba(0, 124, 178, $bg_alpha_std);

$blue_dark: rgba(1, 23, 47, 1);
$blue_dark_wa_zero: rgba(1, 23, 47, 0);
$blue_dark_wa_hover: rgba(1, 23, 47, $bg_alpha_hover);
$blue_dark_wa_std: rgba(1, 23, 47, $bg_alpha_std);
$blue_dark_wa_navbar: rgba(1, 23, 47, .5);

$turquoise_medium: rgba(99, 204, 202, 1);
$turquoise_medium_wa_zero: rgba(99, 204, 202, 0);
$turquoise_medium_wa_hover: rgba(99, 204, 202, $bg_alpha_hover);
$turquoise_medium_wa_std: rgba(99, 204, 202, $bg_alpha_std);

$yellow_medium: rgba(249, 200, 14, 1);
$yellow_medium_wa_zero: rgba(249, 200, 14, 0);
$yellow_medium_wa_hover: rgba(249, 200, 14, $bg_alpha_hover);
$yellow_medium_wa_std: rgba(249, 200, 14, $bg_alpha_std);

$green_medium:rgba(6, 214, 160, 1);
$green_medium_wa_zero:rgba(6, 214, 160, 0);
$green_medium_wa_hover:rgba(6, 214, 160, $bg_alpha_hover);
$green_medium_wa_std:rgba(6, 214, 160, $bg_alpha_std);

$brown_medium: rgba(82, 73, 63, 1);
$brown_medium_wa_zero: rgba(82, 73, 63, 0);
$brown_medium_wa_hover: rgba(82, 73, 63, $bg_alpha_hover);
$brown_medium_wa_std: rgba(82, 73, 63, $bg_alpha_std);

// Text color
.color-blue-light
{
    color: $blue_light !important;
}
.color-blue-medium
{
    color: $blue_medium !important;
}
.color-blue-dark
{
    color: $blue_dark !important;
}
.color-turquoise-medium
{
    color: $turquoise_medium !important;
}
.color-yellow-medium
{
    color: $yellow_medium !important;
}
.color-green-medium
{
    color: $green_medium !important;
}
.color-brown-medium
{
    color: $brown_medium !important;
}
.color-error
{
    color: red !important;
}
.color-success
{
    color: green !important;
}

// Background color
.bg-blue-dark
{
    background-color: $blue_dark !important;
}
.bg-blue-medium
{
    background-color: $blue_medium !important;
}
.bg-blue-light
{
    background-color: $blue_light !important;
}
.bg-yellow-medium
{
    background-color: $yellow_medium !important;
}
.bg-turquoise-medium
{
    background-color: $turquoise_medium !important;
}
.bg-green-medium
{
    background-color: $green_medium !important;
}
.bg-brown-medium
{
    background-color: $brown_medium !important;
}
.bg-color-white
{
    background-color: white;
}

// Background gradient
.bg-blue-gradient
{
    background: transparent linear-gradient(180deg, #007CB2 0%, #01172F 100%) 0% 0% no-repeat padding-box;
}
.bg-blue-gradient-2
{
    background: transparent linear-gradient(180deg, $turquoise_medium 0%, $blue_medium 100%) 0% 0% no-repeat padding-box;
}
.bg-blue-gradient-3
{
    background: transparent linear-gradient(180deg,#71c7ec -50%, hsl(198, 63%, 80%) 70%, #F4FAFF 100%) 0% 0% no-repeat padding-box;
}

// Background color with alpha standard
.bg-blue-dark-wa-std
{
    background-color: $blue_dark_wa_std !important;
}
.bg-blue-medium-wa-std
{
    background-color: $blue_medium_wa_std !important;
}
.bg-blue-light-wa-std
{
    background-color: $blue_light_wa_std !important;
}
.bg-yellow-medium-wa-std
{
    background-color: $yellow_medium_wa_std !important;
}
.bg-green-medium-wa-std
{
    background-color: $green_medium_wa_std !important;
}
.bg-brown-medium-wa-std
{
    background-color: $brown_medium_wa_std !important;
}
.bg-color-white-wa-std
{
    background-color: rgba(1, 1, 1, $bg_alpha_std) !important;
}


// Background color disable in large screen
.bg-blue-light-wa-dils
{
    background-color: $blue_light_wa_std !important;
}
.bg-blue-medium-wa-dils
{
    background-color: $blue_medium_wa_std !important;
}
.bg-blue-dark-wa-dils
{
    background-color: $blue_dark_wa_std !important;
}
.bg-turquoise-medium-wa-dils
{
    background-color: $turquoise_medium_wa_std !important;
}
.bg-green-medium-wa-dils
{
    background-color: $green_medium_wa_std !important;
}
.bg-yellow-medium-wa-dils
{
    background-color: $yellow_medium_wa_std !important;
}
.bg-brown-medium-wa-dils
{
    background-color: $brown_medium_wa_std !important;
}

@media (min-width: 992px)
{
    .bg-blue-light-wa-dils
    {
        background-color: $blue_light_wa_zero !important;
    }
    .bg-blue-medium-wa-dils
    {
        background-color: $blue_medium_wa_zero !important;
    }
    .bg-blue-dark-wa-dils
    {
        background-color: $blue_dark_wa_zero !important;
    }
    .bg-turquoise-medium-wa-dils
    {
        background-color: $turquoise_medium_wa_zero !important;
    }
    .bg-green-medium-wa-dils
    {
        background-color: $green_medium_wa_zero !important;
    }
    .bg-yellow-medium-wa-dils
    {
        background-color: $yellow_medium_wa_zero !important;
    }
    .bg-brown-medium-wa-dils
    {
        background-color: $brown_medium_wa_zero !important;
    }
}
