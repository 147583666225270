
button:focus
{
    outline: 0;
}

button
{
    background-color: transparent;
}
input[type="button"]:disabled 
{
     background: #ccc;
 }

.standardButton
{
    display: block;
    
    color: $yellow_medium !important;
    border: 4px solid $yellow_medium;
    padding: calc(1rem - 8px) calc(2rem - 8px);
    text-decoration: none;
    width: fit-content;
    cursor: pointer;
}

.standardButton:hover
{
    color: $blue_light !important;
    background-color: $yellow_medium;
}

.standardButtonLight
{
    display: block;
    
    color: $yellow_medium !important;
    border: 2px solid $yellow_medium;
    padding: calc(.75rem - 8px) calc(1.5rem - 8px);
    margin: .2rem .2rem;
    text-decoration: none;
    width: fit-content;
    cursor: pointer;
}
.standardButtonLight:hover
{
    color: $blue_light !important;
    background-color: $yellow_medium;
}

.standardButtonBlueMedium
{
    display: block;

    color: $blue_medium !important;
    border: 4px solid $blue_medium;
    padding: calc(1rem - 8px) calc(2rem - 8px);
    text-decoration: none;
    width: fit-content;
    cursor: pointer;
}
.standardButtonBlueMedium:hover
{
    color: $blue_light !important;
    background-color: $blue_medium;
}

.standardButtonBlueDark
{
    display: block;

    color: $blue_dark !important;
    border: 4px solid $blue_dark;
    padding: calc(1rem - 8px) calc(2rem - 8px);
    text-decoration: none;
    width: fit-content;
    cursor: pointer;
}
.standardButtonBlueDark:hover
{
    color: $blue_light !important;
    background-color: $blue_dark;
}

.standardButtonBlueLight
{
    display: block;

    color: $blue_light !important;
    border: 4px solid $blue_light;
    padding: calc(1rem - 8px) calc(2rem - 8px);
    text-decoration: none;
    width: fit-content;
    cursor: pointer;
}
.standardButtonBlueLight:hover
{
    color: $blue_dark !important;
    background-color: $blue_light;
}