
@import "../../app/components/menu/menu.component.scss";

// Color stroke
.stroke-blue-dark
{
    stroke: $blue_dark !important;
}
.stroke-blue-medium
{
    stroke: $blue_medium !important;
}
.stroke-blue-light
{
    stroke: $blue_light !important;
}
.stroke-turquoise-medium
{
    stroke: $turquoise_medium !important;
}

.anim-navbar-open .stroke-blue-light
{
    stroke: $blue_light !important;
    
    transition: stroke $duration_navbar $easing_navbar $delay_navbar_open;
}
.anim-navbar-close .stroke-blue-dark
{
    stroke: $blue_dark !important;

    transition: stroke $duration_navbar $easing_navbar $delay_navbar_close;
}