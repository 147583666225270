
@import "~assets/styles/anim.scss";
@import "~assets/styles/colors.scss";

.menu
{
    z-index: 99;
    max-height: 6rem;
}
.menu-txt
{
    min-height: 2rem;
}

.navbar-zindex
{
    z-index: 1;
}
.menu-button
{
    cursor: pointer;
}

.navbar
{
    padding: 0 !important;
}
.navbar-brand
{
    padding: 0 !important;
}

.hr-sep
{
    color: $blue_light;
}

.anim-button .flag
{
    opacity: .6;
    transition: opacity .2s ease-in;
}
.anim-button:hover .flag
{
    opacity: 1;
}

.anim-button-opacity-min
{
    opacity: 0.5;
    transition: opacity .2s ease-in;
}
.anim-button-opacity-max:hover > div
{
    opacity: 1 !important;
}

.anim-button .color-blue-medium,
.anim-button .color-turquoise-medium
{
    transition: color .2s ease-in-out;
}

.anim-button:hover .color-blue-medium,
.anim-button:hover .color-turquoise-medium,
.menu-txt:hover .color-blue-light,
.menu-txt:hover .color-blue-dark,
.menu-txt:hover .color-turquoise-medium
{
    color: $yellow_medium !important;
    transition: color .2s ease-in-out;
}

.anim-button:hover .stroke-blue-dark,
.anim-button:hover .stroke-blue-medium,
.anim-button:hover .stroke-blue-light,
.anim-button:hover .stroke-turquoise-medium,
.menu-txt:hover .stroke-blue-dark,
.menu-txt:hover .stroke-blue-medium,
.menu-txt:hover .stroke-blue-light,
.menu-txt:hover .stroke-turquoise-medium
{
    stroke: $yellow_medium !important;
    transition: color .2s ease-in-out;
}

// Navbar
.logo-memb-open
{
    fill: $blue_light;
    transition: fill $duration_navbar $easing_navbar $delay_navbar_open;
}
.logo-memb-close
{
    fill-rule: evenodd;
    fill: $blue_dark;
    transition: fill $duration_navbar $easing_navbar $delay_navbar_close;
}
.anim-navbar-open
{
    background-color: $blue_dark;
    transition: background-color $duration_navbar $easing_navbar $delay_navbar_open;
}
.anim-navbar-close
{
    background-color: $blue_light;
    transition: background-color $duration_navbar $easing_navbar $delay_navbar_close;
}

.anim-navbar-open .color-blue-light
{
    color: $blue_light;
    transition: color $duration_navbar $easing_navbar $delay_navbar_open;
}
.anim-navbar-close .color-blue-dark
{
    color: $blue_dark;
    transition: color $duration_navbar $easing_navbar $delay_navbar_close;
}

// Subpanel
.anim-panel-open
{
    box-shadow: 0px 3px 8px rgba($color: black, $alpha: 0.4) !important;
    transform: translate(0, 0) !important;

    transition: all $duration_submenu $easing_submenu_open $delay_submenu_open, box-shadow $duration_submenu $easing_submenu_open $delay_submenu_open;
}
.anim-panel-close
{
    box-shadow: 0px 3px 8px rgba($color: black, $alpha: 0);
    transform: translate(0, -100%);

    transition: all $duration_submenu $easing_submenu_close $delay_submenu_close, box-shadow $duration_submenu $easing_submenu_close $delay_submenu_close;
}
.anim-panel-open-opacity
{
    opacity: 1 !important;
    transition: opacity $duration_submenu $easing_submenu_open $delay_button_open;
}
.anim-panel-close-opacity
{
    opacity: 0;
    transition: opacity $duration_submenu $easing_submenu_close $delay_button_close;
}