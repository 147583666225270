
.facebook
{
    display: block;
    background-image: url("../images/social/facebook.png");
    background-size: cover;
    width: 3rem;
    height: 3rem;
}
.facebook:hover
{
    opacity: .8;
}
.instagram
{
    display: block;
    background-image: url("../images/social/instagram.png");
    background-size: cover;
    width: 3rem;
    height: 3rem;
}
.instagram:hover
{
    opacity: .8;
}
.vimeo
{
    display: block;
    background-image: url("../images/social/vimeo.png");
    background-size: cover;
    width: 3rem;
    height: 3rem;
}
.vimeo:hover
{
    opacity: .8;
}
.youtube
{
    display: block;
    background-image: url("../images/social/youtube.png");
    background-size: cover;
    width: 3rem;
    height: 3rem;
}
.youtube:hover
{
    opacity: .8;
}