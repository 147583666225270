
// Bottom
.mb-8r {
    margin-bottom: 0.5rem !important;
}
.mb-16r {
    margin-bottom: 1rem !important;
}   
.mb-24r {
    margin-bottom: 1.5rem !important;
}
.mb-32r {
    margin-bottom: 2rem !important;
}
.mb-40r {
    margin-bottom: 2.5rem !important;
} 
.mb-48r {
    margin-bottom: 3rem !important;
}
.mb-56r {
    margin-bottom: 3.5rem !important;
}
.mb-64r {
    margin-bottom: 4rem !important;
}
.mb-72r {
    margin-bottom: 4.5rem !important;
}
.mb-96r {
    margin-bottom: 6rem !important;
}
.mb-128r {
    margin-bottom: 8rem !important;
}
.mb-256r {
    margin-bottom: 16rem !important;
}

// Top
.mt-8r {
    margin-top: 0.5rem !important;
}
.mt-16r {
    margin-top: 1rem !important;
}   
.mt-24r {
    margin-top: 1.5rem !important;
}
.mt-32r {
    margin-top: 2rem !important;
}
.mt-40r {
    margin-top: 2.5rem !important;
}
.mt-48r {
    margin-top: 3rem !important;
}
.mt-56r {
    margin-top: 3.5rem !important;
}
.mt-64r {
    margin-top: 4rem !important;
}
.mt-72r {
    margin-top: 4.5rem !important;
}
.mt-96r {
    margin-top: 6rem !important;
}
.mt-128r {
    margin-top: 8rem !important;
}
.mt-256r {
    margin-top: 16rem !important;
}

// Bottom - Top
.my-8r {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}
.my-16r {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}   
.my-24r {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}
.my-32r {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
}
.my-40r {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
} 
.my-48r {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}
.my-56r {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
}
.my-64r {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
}
.my-72r {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
}
.my-96r {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
}
.my-128r {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
}
.my-256r {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
}


// Left
.ml-8r {
    margin-left: 0.5rem !important;
}
.ml-16r {
    margin-left: 1rem !important;
}   
.ml-24r {
    margin-left: 1.5rem !important;
}
.ml-32r {
    margin-left: 2rem !important;
}
.ml-40r {
    margin-left: 2.5rem !important;
}
.ml-48r {
    margin-left: 3rem !important;
}
.ml-56r {
    margin-left: 3.5rem !important;
}
.ml-64r {
    margin-left: 4rem !important;
}
.ml-72r {
    margin-left: 4.5rem !important;
}
.ml-96r {
    margin-left: 6rem !important;
}
.ml-128r {
    margin-left: 8rem !important;
}
.ml-256r {
    margin-left: 16rem !important;
}

// Right
.mr-8r {
    margin-right: 0.5rem !important;
}
.mr-16r {
    margin-right: 1rem !important;
}   
.mr-24r {
    margin-right: 1.5rem !important;
}
.mr-32r {
    margin-right: 2rem !important;
}
.mr-40r {
    margin-right: 2.5rem !important;
}
.mr-48r {
    margin-right: 3rem !important;
}
.mr-56r {
    margin-right: 3.5rem !important;
}
.mr-64r {
    margin-right: 4rem !important;
}
.mr-72r {
    margin-right: 4.5rem !important;
}
.mr-96r {
    margin-right: 6rem !important;
}
.mr-128r {
    margin-right: 8rem !important;
}
.mr-256r {
    margin-right: 16rem !important;
}

// Left - Right
.mx-8r {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
}
.mx-16r {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
}   
.mx-24r {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
}
.mx-32r {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
}
.mx-40r {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
} 
.mx-48r {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
}
.mx-64r {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
}
.mx-72r {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
} 
.mx-96r {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
}
.mx-128r {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
}
.mx-256r {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
}





// Bottom
.pb-8r {
    padding-bottom: 0.5rem !important;
}
.pb-16r {
    padding-bottom: 1rem !important;
}   
.pb-24r {
    padding-bottom: 1.5rem !important;
}
.pb-32r {
    padding-bottom: 2rem !important;
}
.pb-40r {
    padding-bottom: 2.5rem !important;
} 
.pb-48r {
    padding-bottom: 3rem !important;
}
.pb-56r {
    padding-bottom: 3.5rem !important;
}
.pb-64r {
    padding-bottom: 4rem !important;
}
.pb-72r {
    padding-bottom: 4.5rem !important;
} 
.pb-96r {
    padding-bottom: 6rem !important;
}
.pb-128r {
    padding-bottom: 8rem !important;
}
.pb-256r {
    padding-bottom: 16rem !important;
}

// Top
.pt-8r {
    padding-top: 0.5rem !important;
}
.pt-16r {
    padding-top: 1rem !important;
}   
.pt-24r {
    padding-top: 1.5rem !important;
}
.pt-32r {
    padding-top: 2rem !important;
}
.pt-40r {
    padding-top: 2.5rem !important;
} 
.pt-48r {
    padding-top: 3rem !important;
}
.pt-56r {
    padding-top: 3.5rem !important;
} 
.pt-64r {
    padding-top: 4rem !important;
}
.pt-72r {
    padding-top: 4.5rem !important;
} 
.pt-96r {
    padding-top: 6rem !important;
}
.pt-128r {
    padding-top: 8rem !important;
}
.pt-256r {
    padding-top: 16rem !important;
}

// Bottom - Top
.py-8r {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}
.py-16r {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}   
.py-24r {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}
.py-32r {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
}
.py-40r {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
}
.py-48r {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}
.py-56r {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
}
.py-64r {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
}
.py-72r {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
}
.py-96r {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
}
.py-128r {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
}
.py-256r {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
}

// Left
.pl-8r {
    padding-left: 0.5rem !important;
}
.pl-16r {
    padding-left: 1rem !important;
}   
.pl-24r {
    padding-left: 1.5rem !important;
}
.pl-32r {
    padding-left: 2rem !important;
}
.pl-40r {
    padding-left: 2.5rem !important;
} 
.pl-48r {
    padding-left: 3rem !important;
}
.pl-56r {
    padding-left: 3.5rem !important;
} 
.pl-64r {
    padding-left: 4rem !important;
}
.pl-72r {
    padding-left: 4.5rem !important;
} 
.pl-96r {
    padding-left: 6rem !important;
}
.pl-128r {
    padding-left: 8rem !important;
}
.pl-256r {
    padding-left: 16rem !important;
}

// Right
.pr-8r {
    padding-right: 0.5rem !important;
}
.pr-16r {
    padding-right: 1rem !important;
}   
.pr-24r {
    padding-right: 1.5rem !important;
}
.pr-32r {
    padding-right: 2rem !important;
} 
.pr-40r {
    padding-right: 2.5rem !important;
} 
.pr-48r {
    padding-right: 3rem !important;
}
.pr-56r {
    padding-right: 3.5rem !important;
} 
.pr-64r {
    padding-right: 4rem !important;
}
.pr-72r {
    padding-right: 4.5rem !important;
} 
.pr-96r {
    padding-right: 6rem !important;
}
.pr-128r {
    padding-right: 8rem !important;
}
.pr-256r {
    padding-right: 16rem !important;
}

// Left - Right
.px-8r {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}
.px-16r {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}   
.px-24r {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}
.px-32r {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
} 
.px-40r {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
}
.px-48r {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
}
.px-56r {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
}
.px-64r {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
}
.px-72r {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
}
.px-96r {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
}
.px-128r {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
}
.px-256r {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
}