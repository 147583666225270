
@font-face
{
    font-family: 'LettersForLearners';
    src: url('../../assets/fonts/LettersForLearners.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/LettersForLearners.woff') format('woff'),
         url('../../assets/fonts/LettersForLearners.woff2') format('woff2'),
         url('../../assets/fonts/LettersForLearners.ttf')  format('truetype'),
         url('../../assets/fonts/LettersForLearners.svg#svgFontName') format('svg');
}
@font-face
{
    font-family: 'SourceSansPro-Regular';
    src: url('../../assets/fonts/SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/SourceSansPro-Regular.woff') format('woff'),
         url('../../assets/fonts/SourceSansPro-Regular.woff2') format('woff2'),
         url('../../assets/fonts/SourceSansPro-Regular.ttf')  format('truetype'),
         url('../../assets/fonts/SourceSansPro-Regular.svg#svgFontName') format('svg');
}
@font-face
{
    font-family: 'SourceSansPro-Bold';
    src: url('../../assets/fonts/SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/SourceSansPro-Bold.woff') format('woff'),
         url('../../assets/fonts/SourceSansPro-Bold.woff2') format('woff2'),
         url('../../assets/fonts/SourceSansPro-Bold.ttf')  format('truetype'),
         url('../../assets/fonts/SourceSansPro-Bold.svg#svgFontName') format('svg');
}
@font-face
{
    font-family: 'SourceSansPro-SemiBold';
    src: url('../../assets/fonts/SourceSansPro-SemiBold.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/SourceSansPro-SemiBold.woff') format('woff'),
         url('../../assets/fonts/SourceSansPro-SemiBold.woff2') format('woff2'),
         url('../../assets/fonts/SourceSansPro-SemiBold.ttf')  format('truetype'),
         url('../../assets/fonts/SourceSansPro-SemiBold.svg#svgFontName') format('svg');
}
@font-face
{
    font-family: 'SourceSansPro-LightItalic';
    src: url('../../assets/fonts/SourceSansPro-LightItalic.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/SourceSansPro-LightItalic.woff') format('woff'),
         url('../../assets/fonts/SourceSansPro-LightItalic.woff2') format('woff2'),
         url('../../assets/fonts/SourceSansPro-LightItalic.ttf')  format('truetype'),
         url('../../assets/fonts/SourceSansPro-LightItalic.svg#svgFontName') format('svg');
}
@font-face
{
    font-family: 'SourceSansPro-Light';
    src: url('../../assets/fonts/SourceSansPro-Light.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/SourceSansPro-Light.woff') format('woff'),
         url('../../assets/fonts/SourceSansPro-Light.woff2') format('woff2'),
         url('../../assets/fonts/SourceSansPro-Light.ttf')  format('truetype'),
         url('../../assets/fonts/SourceSansPro-Light.svg#svgFontName') format('svg');
}
@font-face
{
    font-family: 'SourceSansPro-Black';
    src: url('../../assets/fonts/SourceSansPro-Black.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/SourceSansPro-Black.woff') format('woff'),
         url('../../assets/fonts/SourceSansPro-Black.woff2') format('woff2'),
         url('../../assets/fonts/SourceSansPro-Black.ttf')  format('truetype'),
         url('../../assets/fonts/SourceSansPro-Black.svg#svgFontName') format('svg');
}
@font-face
{
    font-family: 'SourceSansPro-Italic';
    src: url('../../assets/fonts/SourceSansPro-Italic.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/SourceSansPro-Italic.woff') format('woff'),
         url('../../assets/fonts/SourceSansPro-Italic.woff2') format('woff2'),
         url('../../assets/fonts/SourceSansPro-Italic.ttf')  format('truetype'),
         url('../../assets/fonts/SourceSansPro-Italic.svg#svgFontName') format('svg');
}
@font-face
{
    font-family: 'SourceSansPro-BoldItalic';
    src: url('../../assets/fonts/SourceSansPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/SourceSansPro-BoldItalic.woff') format('woff'),
         url('../../assets/fonts/SourceSansPro-BoldItalic.woff2') format('woff2'),
         url('../../assets/fonts/SourceSansPro-BoldItalic.ttf')  format('truetype'),
         url('../../assets/fonts/SourceSansPro-BoldItalic.svg#svgFontName') format('svg');
}

.typo_1
{
    font-family: 'SourceSansPro-Black';
    font-size: 4rem;
    letter-spacing: 0;
    line-height: 4rem;
    color: $blue_dark;
}
.typo_2
{
    font-family: 'SourceSansPro-SemiBold';
    font-size: 2.5rem;
    letter-spacing: -0.38px;
    line-height: 2.75rem;
    color: $blue_dark;
} 
.typo_2_bis
{
    font-family: 'SourceSansPro-Regular';
    font-size: 3rem;
    letter-spacing: 0.38px;
    color: $blue_dark;
}
.typo_3
{
    font-family: 'SourceSansPro-Light';
    font-size: 1.75rem;
    letter-spacing: 0;
    line-height: 2.5rem;
    color: $blue_dark;
}
.typo_4
{
    font-family: 'LettersForLearners';
    font-size: 1.5rem;
    letter-spacing: -0.22px;
    line-height: 1.75rem;
    color: $blue_dark;
}
.typo_4_B
{
    font-family: 'LettersForLearners';
    font-size: 1.5rem;
    letter-spacing: 0.38px;
    line-height: 1.5rem;
    color: $blue_light;
}
.typo_4_C
{
    font-family: 'LettersForLearners';
    font-size: 1.75rem;
    letter-spacing: 0.38px;
    line-height: 1.75rem;
    color: $blue_light;
}
.typo_5
{
    font-family: 'SourceSansPro-Bold';
    font-size: 5rem;
    letter-spacing: -5.76px;
    line-height: 5rem;
    color: $blue_dark;
}
.typo_6
{
    font-family: 'SourceSansPro-SemiBold';
    font-size: 1.5rem;
    letter-spacing: -0.32px;
    line-height: 1.5rem;
    color: $blue_dark;
}
.typo_7
{
    font-family: 'SourceSansPro-SemiBold';
    font-size: 1.25rem;
    letter-spacing: -0.32px;
    color: $blue_light_wa_navbar;
    text-transform: uppercase;
}
.typo_7_B
{
    font-family: 'SourceSansPro-Regular';
    font-size: 1.5rem;
    letter-spacing: -0.32px;
    color: $blue_dark;
    text-transform: uppercase;
}
.typo_8
{
    font-family: 'SourceSansPro-SemiBold';
    font-size: 1rem;
    letter-spacing: 0.64px;
    color: $blue_dark;
    text-transform: uppercase;
}
.typo_9
{
    font-family: 'SourceSansPro-Regular';
    font-size: 1.5rem;
    letter-spacing: 0.38px;
    color: $blue_dark;
}
.typo_9_B
{
    font-family: 'SourceSansPro-Regular';
    font-size: 1.5rem;
    letter-spacing: -0.77px;
    color: $blue_dark;
}
.typo_10_A
{
    font-family: 'SourceSansPro-LightItalic';
    font-size: 1rem;
    letter-spacing: 0;
    color: $blue_dark;
}
.typo_10_B
{
    font-family: 'SourceSansPro-Regular';
    font-size: 1rem;
    letter-spacing: 0;
    color: $blue_dark;
}
.typo_10_C
{
    font-family: 'SourceSansPro-Regular';
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 1rem;
    color: $blue_medium;
}
.typo_10_D
{
    font-family: 'SourceSansPro-BoldItalic';
    font-size: 1.2rem;
    letter-spacing: 0;
    color: $blue_dark;
}
.typo_10_E
{
    font-family: 'SourceSansPro-LightItalic';
    font-size: 1rem;
    letter-spacing: 0;
    color: white;
}
.typo_submenu
{
    font-family: 'SourceSansPro-SemiBold';
    font-size: 1.25rem;
    line-height: 1.25rem;
    letter-spacing: -0.32px;
    color: $blue_light_wa_navbar;
    text-transform: uppercase;
}
.typo_footer
{
    font-family: 'SourceSansPro-SemiBold';
    font-size: 3rem;
    letter-spacing: 0.38px;
    color: $blue_light;
}

@media (max-width: 768px)
{
    .typo_1
    {
        font-size: calc(4rem * .75);
        line-height: calc(4rem * .75);
    }
    .typo_2
    {
        font-size: calc(2.5rem * .75);
        line-height: calc(2.5rem * .75);
    }
    .typo_2_bis
    {
        font-size: calc(3rem * .75);
    }
    .typo_3
    {
        font-size: calc(1.75rem * .75);
        line-height: calc(2.5rem * .75);
    }
    .typo_4
    {
        font-size: calc(1.5rem * 1);
        line-height: calc(1.75rem * 1);
    }
    .typo_4_B
    {
        font-size: calc(1.5rem * .75);
        line-height: calc(1.5rem * .75);
    }
    .typo_4_C
    {
        font-size: calc(1.75rem * .75);
        line-height: calc(1.75rem * .75);
    }
    .typo_5
    {
        font-size: calc(5rem * .75);
        line-height: calc(5rem * .75);
    }
    .typo_6
    {
        font-size: calc(1.5rem * .75);
        line-height: calc(1.6rem * .75);
    }
    .typo_7
    {
        font-size: calc(1.25rem * .75);
    }
    .typo_7_B
    {
        font-size: calc(1.5rem * .75);
    }
    .typo_8
    {
        font-size: calc(1rem * 1);
    }
    .typo_9
    {
        font-size: calc(1.5rem * .75);
    }
    .typo_9_B
    {
        font-size: calc(1.5rem * .75);
    }
    .typo_10_A
    {
        font-size: calc(1rem * .75);
    }
    .typo_10_B
    {
        font-size: calc(1rem * .85);
    }
    .typo_10_C
    {
        font-size: calc(1rem * .75);
        line-height: calc(1rem * .75);
    }
    .typo_10_D
    {
        font-size: calc(1.2rem * .75);
    }
    .typo_10_E
    {
        font-size: calc(1.2rem * .75);
    }
    .typo_submenu
    {
        font-size: calc(1.25rem * .7);
        line-height: calc(1.25rem * .7);
    }
    .typo_footer
    {
        font-size: calc(3rem * .4);
        line-height: calc(3rem * .4);
    }
}